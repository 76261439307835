import {
  Box,
  Button,
  FormField,
  Input,
  Textarea,
} from "@amzn/awsui-components-react";
import * as React from "react";
import { UIModel } from "../../model/ui-model";
import { SmartRichTextView } from "./RichTextEditor/SmartRichTextView";
import { StrongRichTextEdit } from "./RichTextEditor/TinyMCEBased/StrongRichTextEdit";

export namespace UIField {
  export const ValueField = (props: { name: string; value: string }) => {
    return (
      <FormField
        label={
          <Box margin={{ bottom: "xxxs" }} color="text-label">
            {props.name}
          </Box>
        }
      >
        <div>{props.value}</div>
      </FormField>
    );
  };

  export const LabelField = (props: { label: string; children: any }) => (
    <div>
      <Box margin={{ bottom: "xxxs" }} color="text-label">
        {props.label}
      </Box>
      <div>{props.children}</div>
    </div>
  );

  export const NumberValueField = (props: {
    name: string;
    state: UIModel.State<number>;
    editing: boolean;
    placeholder?: string;
  }) => {
    const [blurred, setBlurred] = React.useState<boolean>(false);
    return (
      <FormField
        label={
          <Box margin={{ bottom: "xxxs" }} color="text-label">
            {props.name}
          </Box>
        }
        errorText={blurred && props.state.errorText}
      >
        {props.editing && (
          <Input
            type="number"
            placeholder={props.placeholder ? props.placeholder : props.name}
            value={props.state.value}
            onChange={(e: { detail: { value: string } }) => {
              const num = parseInt(e.detail.value);
              props.state.setValue(num);
            }}
            onBlur={() => setBlurred(true)}
          />
        )}
        {!props.editing && <div>{props.state.value}</div>}
      </FormField>
    );
  };

  /**
   * use Controlled field to control edtiable state
   * if controlled is true, then editing field is always ignored
   * @param props
   * @returns
   */
  export const StateValueField = (props: {
    name: string;
    state: UIModel.State<string>;
    editing?: boolean;
    placeholder?: string;
    variant?: "Input" | "TextArea";
    constraintText?: React.ReactNode;
    controlled?: boolean;
    helpInfo?: React.ReactNode;
    disabled?: boolean;
  }) => {
    const variant = props.variant || "Input";
    const [blurred, setBlurred] = React.useState<boolean>(false);
    const [controlledEditing, setControlledEditing] =
      React.useState<boolean>(false);

    const editing =
      (props.controlled && controlledEditing) ||
      (!props.controlled && props.editing);
    return (
      <FormField
        label={
          <Box margin={{ bottom: "xxxs" }} color="text-label">
            {props.name}
            {props.editing && props.helpInfo ? <>&nbsp;{props.helpInfo}</> : ""}
          </Box>
        }
        errorText={blurred && props.state.errorText}
        secondaryControl={
          props.controlled &&
          (controlledEditing ? (
            <Button onClick={() => setControlledEditing(false)}>Done</Button>
          ) : (
            <Button onClick={() => setControlledEditing(true)}>Edit</Button>
          ))
        }
        constraintText={props.editing ? props.constraintText : ""}
      >
        {editing && variant === "Input" && (
          <Input
            placeholder={props.placeholder ? props.placeholder : props.name}
            value={props.state.value}
            onChange={(e: { detail: { value: string } }) =>
              props.state.setValue(e.detail.value)
            }
            onBlur={() => setBlurred(true)}
            disabled={props.disabled}
          />
        )}
        {editing && variant === "TextArea" && (
          <Textarea
            placeholder={props.placeholder ? props.placeholder : props.name}
            value={props.state.value}
            onChange={(e: { detail: { value: string } }) =>
              props.state.setValue(e.detail.value)
            }
            onBlur={() => setBlurred(true)}
            disabled={props.disabled}
          />
        )}
        {!editing && (
          <div>
            {!!props.state.value && props.state.value.length > 0
              ? props.state.value
              : "-"}
          </div>
        )}
      </FormField>
    );
  };

  export const CustomField = (props: {
    name: string;
    child: JSX.Element;
    description?: string;
    errorText?: string;
    helpInfo?: React.ReactNode;
  }) => {
    return (
      <FormField
        label={
          <Box margin={{ bottom: "xxxs" }} color="text-label">
            {props.name}
            {props.helpInfo ? <>&nbsp;{props.helpInfo}</> : ""}
          </Box>
        }
        description={props.description}
        errorText={props.errorText}
      >
        {props.child}
      </FormField>
    );
  };

  /**
   * use Controlled field to control edtiable state
   * if Controlled is set to true, then editing field will always be ignored
   * @param props
   * @returns
   */
  export const RichTextField = (props: {
    name: string;
    state: UIModel.State<string>;
    editing?: boolean;
    placeholder?: string;
    constraintText?: React.ReactNode;
    controlled?: boolean;
  }) => {
    const [blurred, setBlurred] = React.useState<boolean>(false);
    const [controlledEditing, setControlledEditing] =
      React.useState<boolean>(false);

    const editing =
      (props.controlled && controlledEditing) ||
      (!props.controlled && props.editing);
    return (
      <FormField
        label={
          <Box margin={{ bottom: "xxxs" }} color="text-label">
            {props.name}
          </Box>
        }
        secondaryControl={
          props.controlled &&
          (controlledEditing ? (
            <Button onClick={() => setControlledEditing(false)}>Done</Button>
          ) : (
            <Button onClick={() => setControlledEditing(true)}>Edit</Button>
          ))
        }
        errorText={blurred && props.state.errorText}
        constraintText={props.editing ? props.constraintText : ""}
      >
        {editing && (
          <StrongRichTextEdit
            content={props.state.value}
            onChange={(value: string) => props.state.setValue(value)}
          />
        )}
        {!editing && (
          <SmartRichTextView
            content={
              !!props.state.value && props.state.value.length > 0
                ? props.state.value
                : "-"
            }
          />
        )}
      </FormField>
    );
  };
}
