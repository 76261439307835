import { scroller } from "react-scroll";
import $ = require("jquery");
import {
    AMZN_APPROVALS_LINK,
    AMZN_APPROVALS_STAGE_LINK,
    Container,
    InstanceFactory,
    KendraConst,
    SHARED_CATEGORY_ATTRIBUTE_VALUE,
    Stage
} from "@amzn/ask-legal-domain";
import { QueryResultItem } from "@aws-sdk/client-kendra";
import { Document } from "@amzn/ask-legal-domain";

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

export async function registerSamePageLinkEvent() {
    // JQuery event registration needs to be done after DOM is rendered
    // wait 1 second so all DOM are surely to be rendered, otherwise registration might fail
    // causing the JQuery function not triggered
    await sleep(1000);
    $(".custom-container-link").off().on("click", (event) => {
        const targetContainerId = event.currentTarget.attributes["container-id"].nodeValue;
        scroller.scrollTo(targetContainerId, {
            duration: 750,
            delay: 50,
            smooth: true,
            offset: -50, // Scrolls to element -50 pixels down the page
        });
    });
}

export function getApprovalBaseUrl(stage: string) {
    if (!stage || stage !== Stage.Prod) return AMZN_APPROVALS_STAGE_LINK;
    return AMZN_APPROVALS_LINK;
}

export function getSharedPageUrl(props: { pageId: string, instanceId: string }) {
    return `${window.location.origin}#/shared-page/${props.instanceId}/${props.pageId}/live`;
}

/**
 * This function returns the correct url for a kendra document
 * if it is a document shared from a different instance
 * @param resultItem QueryResultItem from Kendra
 * @param instanceId current instance Id
 */
export const getDocumentUri = (props: { resultItem: QueryResultItem; instanceId: string; }) => {
    let documentUri = props.resultItem.DocumentURI;
    const sharedDocumentAttribute = props.resultItem.DocumentAttributes.find(a => a.Key === KendraConst.ContainerIngestionKendraAttributes.Custom.AdditionalCategories)?.Value?.StringValue;
    if (!sharedDocumentAttribute || sharedDocumentAttribute !== SHARED_CATEGORY_ATTRIBUTE_VALUE) {
        return documentUri;
    }

    const documentPageId = props.resultItem.DocumentAttributes.find((a => a.Key === KendraConst.Attributes.Custom.PageId))?.Value?.StringValue;
    if (!documentPageId) {
        return documentUri;
    }

    const documentInstanceId = InstanceFactory.fromEntityId(documentPageId);

    let currentInstanceId = props.instanceId;
    if (documentInstanceId === currentInstanceId) {
        return documentUri;
    }

    documentUri = getSharedPageUrl({ pageId: documentPageId, instanceId: currentInstanceId });

    return documentUri;
};

export namespace StringUtils {
    export function isEmpty(str: string) {
        return !str || str.length === 0;
    }

    export function prettyPrintDecimal(num: any) {
        return Number(num).toFixed(2);
    }

    /**
     * Function to capitalize the first letter of a string
     */
    export function capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    export function stripHtml(html: string) {
        return html.replace(/<[^>]*>?/gm, "");
    }

    /**
     * Function to extract text from HTML string
     * Returns string if input is not valid HTML
     */
    export function extractTextFromHTML(html: string): string {
        const doc = new DOMParser().parseFromString(html, "text/html");
        return doc.body.textContent || "";
    }
}

// Temporary Method to control release of Draft Compare features
export const isCompareFeatureActivated = (containerType: Container.Type) => {
    switch (containerType) {
        case Container.Type.RICH_TEXT:
        case Container.Type.ACCORDION:
        case Container.Type.ACCORDION_GROUP:
            return true;
        case Container.Type.DOCUMENT:
        case Container.Type.LIST:
        default:
            return false;
    }
};

/**
 * This function can scroll the page view to the target container with a delay
 * The delay is used to wait for the page to be rendered
 * @param delayInMs
 * @param targetContainerId
 */
export async function scrollWithDelayInMs(delayInMs: number, targetContainerId: string) {
    const element = document.getElementById(targetContainerId);
    if (element) {
        if (delayInMs > 0) {
            await sleep(delayInMs);
        }
        scroller.scrollTo(targetContainerId, {
            duration: 750,
            delay: 50,
            smooth: true,
            offset: -50, // Scrolls to element -50 pixels down the page
        });
    }
}

/**
 * @returns A map containing current query parameters from current URL
 */
export function getQueryParameterMap(): Map<string, string> {
    const urlsearchparams = new URLSearchParams(window.location.href.split("?")[1]);
    const map = new Map<string, string>();
    urlsearchparams.forEach((value, key) => {
        map.set(key, value);
    });
    return map;
}

export function getObjectSizeinBytes(object: any): number {
    const size = new TextEncoder().encode(JSON.stringify(object)).length;
    return size;
}

export namespace UIConstants {
    export const ERROR_MESSAGE = "Oops, something went wrong. Please try again later.";
    export const MAX_EDIT_CONTAINER_SIZE_MB = 5.65; // lambda constraint of 6 MB
    export const MAX_EDIT_CONTAINER_LIMIT_DOCUMENTATION = "https://w.amazon.com/bin/view/ALTAR/Pathfinder/User-Guide/Container-Size-Limit/";
    export const MAX_CONTAINER_METADATA_SIZE = 204800;
}

// Function to check if number is in range
export function isInRange(num: number, min: number, max: number) {
    return num >= min && num <= max;
}

// Compare function to order filenames - Alphabetical in this order 1) Special Character 2) 0-9 3) A-Z 4) a-z
// This is used to sort the documents in the document container
export function documentsComparator(doc_1: Document, doc_2: Document) {
    return doc_1.filename.substring(0, doc_1.filename.lastIndexOf("."))
        .localeCompare(
            doc_2.filename.substring(0, doc_2.filename.lastIndexOf(".")),
            "en",
            { numeric: true, caseFirst: "upper" }
        );
}

export function setURLByQueryParameters(searchParams: Map<string, string>) {
    let url = window.location.href;
    searchParams.delete("mode");
    let params = "";
    searchParams.forEach((val, key) => {
        if (params.length > 0) {
            params += "&";
        }
        params += `${key}=${val}`;
    });
    if (params.length > 0) {
        url = `${url.split("?")[0]}?${params}`;
    }
    window.history.replaceState({}, document.title, url);
}